<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{$t('elearning_iabm.registration')}}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
              <b-col md="5" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="email"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.email') }}
                  </template>
                  <b-form-input
                      id="email"
                      v-model="search.email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5" sm="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="mobile"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.mobile')}}
                  </template>
                  <b-form-input
                      type="number"
                      id="mobile"
                      v-model="search.mobile"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12" class="text-right">
                  <b-button type="button" size="sm" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
          </b-row>
        </template>
      </card>
      <body-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_iabm.registration')}} {{$t('globalTrans.list')}}</h4>
        </template>
        <template v-slot:headerAction>
            <!-- <a href="javascript:" class="btn-add mr-2" @click="pdfExport">
              <i class="fas fa-print"></i> {{ $t('globalTrans.print') }}
            </a> -->
            <router-link  class="btn-add" to="registration-form"><i class="ri-add-fill"></i>{{ $t('globalTrans.add_new') }}</router-link>
        </template>
        <template v-slot:body>
            <b-overlay :show="loadingState">
              <select-column-check :labelData="labelData" :search="search" :columns="columns" />
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                      <template v-slot:cell(serial)="data">
                      {{ $n(data.item.serial + pagination.slOffset) }}
                      </template>
                      <template v-slot:cell(name)="data">
                        <span class="capitalize">{{ data.item.name }}</span>
                      </template>
                      <template v-slot:cell(image)="data">
                        <img :src="trainingElearningServiceBaseUrl + 'storage/' + data.item.image" style="width: 80px; height: 80px; border-radius: 50% !important;" class="center">
                      </template>
                      <!-- <template v-slot:cell(signature)="data">
                        <img :src="trainingElearningServiceBaseUrl + 'storage/' + data.item.signature" style="width: 80px; height: 40px; border-radius: 0px !important;" class="center">
                      </template> -->
                      <template v-slot:cell(mobile)="data">
                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                      </template>
                      <template v-slot:cell(org_id)="data">
                        <slot v-if="data.item.professional_info.org_type_id === 3">
                          {{ $i18n.locale === 'bn' ? data.item.professional_info.org_name_bn : data.item.professional_info.org_name_en }}
                        </slot>
                        <slot v-else>
                          {{ $i18n.locale === 'bn' ? data.item.organization_name_bn : data.item.organization_name }}
                        </slot>
                      </template>
                      <template v-slot:cell(designation_id)="data">
                          {{ $i18n.locale === 'bn' ? data.item.professional_info.designation_bn : data.item.professional_info.designation_en }}
                      </template>
                      <template v-slot:cell(nid)="data">
                        <slot v-if="data.item.nid">
                          {{ $n(data.item.nid, { useGrouping: false }) }}
                        </slot>
                      </template>
                      <template v-slot:cell(date_of_birth)="data">
                        <span class="capitalize">{{ data.item.date_of_birth | dateFormat }}</span>
                      </template>
                      <template v-slot:cell(status)="data">
                          <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                          <span class="badge badge-danger" v-else-if="data.item.status == 2">{{$t('globalTrans.reject')}}</span>
                          <span class="badge badge-success" v-else>{{$t('globalTrans.approved')}}</span>
                      </template>
                      <template v-slot:cell(action)="data">
                          <b-button v-b-modal.modal-5 variant="iq-bg-info" size="sm" class="action-btn btn-info" :title="$t('globalTrans.details')" @click="details(data.item)"><i class="ri-eye-fill"></i></b-button>
                          <router-link class="action-btn edit" variant=" iq-bg-success mr-1" :title="$t('globalTrans.edit')"  :to="{ path: '/training-e-learning-service/iabm/registration-form', query: { id: data.item.id }}"><i class="ri-ball-pen-fill"></i></router-link>
                          <span v-if="data.item.status === 1">
                              <b-button variant="iq-bg-success" size="sm" class="action-btn btn-success" :title="$t('globalTrans.approve')" @click="approve(data.item)"><i class="fa fa-check"></i></b-button>
                          </span>
                          <span v-if="data.item.status === 1">
                            <b-button variant="iq-bg-info" size="sm" class="action-btn btn-danger" :title="$t('globalTrans.reject')" @click="reject(data.item)"><i class="ri-close-circle-fill"></i></b-button>
                              <!-- <a href="javascript:" class="btn_table_action table_action_toggle" @click="reject(data.item)"><i class="fas fa-window-close"></i></a> -->
                          </span>
                      </template>
                  </b-table>
                  <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                    <b-pagination
                      v-model="pagination.currentPage"
                      :perPage="search.limit"
                      :total-rows="pagination.totalRows"
                      @input="searchData"
                    />
                  </div>
                </b-col>
            </b-row>
          </b-overlay>
        </template>
      </body-card>
      <b-modal id="modal-5" size="xl" :title="$t('elearning_iabm.registration') + ' ' + this.$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_iabm.registration') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExportDetails" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :item="item" :key="id" ref="details"/>
      </b-modal>
    </div>
</template>
<script>

// import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { personalInfoList, personalInfoApprove, personalInfoReject } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'
import ExportPdf from './export_pdf_details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        search: {
          email: '',
          mobile: '',
          limit: 20
        },
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        id: 0,
        item: '',
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
          { labels: 'globalTrans.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
          { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '10%' } },
          { labels: 'globalTrans.photo', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
          // { labels: 'globalTrans.signature', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
          { labels: 'externalTraining.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
          { labels: 'globalTrans.email', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } }
        ],
        orgType: [
          {
            value: 1,
            text: 'MOC',
            text_en: 'MOC',
            text_bn: 'এমওসি'
          },
          {
            value: 2,
            text: 'Private',
            text_en: 'Private',
            text_bn: 'ব্যক্তিগত'
          },
          {
            value: 3,
            text: 'Others',
            text_en: 'Others',
            text_bn: 'অন্যান্য'
          }
        ]
      }
    },
    computed: {
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      genderList () {
        const genderList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ', text_en: 'Male', text_bn: 'পুরুষ' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা', text_en: 'Female', text_bn: 'মহিলা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
        ]
        return genderList
      },
      maritalList () {
        const status = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত', text_en: 'Married', text_bn: 'বিবাহিত' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত', text_en: 'Unmarried', text_bn: 'অবিবাহিত' }
        ]
        return status
      },
      bloodGroupList () {
        const bloodGroupList = [
          { value: 1, text: 'A+', text_bn: 'এ+' },
          { value: 2, text: 'A-', text_bn: 'এ-' },
          { value: 3, text: 'B+', text_bn: 'বি+' },
          { value: 4, text: 'B-', text_bn: 'বি-' },
          { value: 5, text: 'O+', text_bn: 'ও+' },
          { value: 6, text: 'O-', text_bn: 'ও-' },
          { value: 7, text: 'AB+', text_bn: 'এবি+' },
          { value: 8, text: 'AB-', text_bn: 'এবি-' }
        ]
      return bloodGroupList
      },
      religionList () {
        const religionList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম', text_en: 'Muslim', text_bn: 'মুসলিম' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু', text_en: 'Hindu', text_bn: 'হিন্দু' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান', text_en: 'Christian', text_bn: 'খ্রিস্টান' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ', text_en: 'Buddhist', text_bn: 'বৌদ্ধ' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
        ]
        return religionList
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
            return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'org_id' },
            { key: 'name_bn' },
            { key: 'image' },
            // { key: 'signature' },
            { key: 'designation_id' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'org_id' },
            { key: 'name' },
            { key: 'image' },
            // { key: 'signature' },
            { key: 'designation_id' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      }
    },
    async created () {
      // this.search = Object.assign({}, this.search, {
      //   org_id: this.$store.state.dataFilters.orgId
      // })
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        async searchData () {
            this.loadData()
        },
        changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              if (destination !== null && dropdownName !== null) {
                Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
              }
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        approve (item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.approveMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatus(trainingElearningServiceBaseUrl, personalInfoApprove, item, 'training_e_learning', 'trainerEvaluationList')
            }
          })
        },
        reject (item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.rejectMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatus(trainingElearningServiceBaseUrl, personalInfoReject, item, 'training_e_learning', 'trainerEvaluationList')
            }
          })
        },
        loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, personalInfoList, params).then(response => {
            if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data, this.search.limit)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
        },
        getCustomDataList (data) {
          const listData = data.map((item, index) => {
            const customItem = {}
            const orgTypeObj = this.orgType.find(data => data.value === item.professional_info.org_type_id)
            if (typeof orgTypeObj !== 'undefined') {
              customItem.org_type_name = orgTypeObj.text_en
              customItem.org_type_name_bn = orgTypeObj.text_bn
            } else {
              customItem.org_type_name = ''
              customItem.org_type_name_bn = ''
            }
            const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
            if (typeof userTypeObj !== 'undefined') {
              customItem.user_type_name = userTypeObj.text_en
              customItem.user_type_name_bn = userTypeObj.text_bn
            } else {
              customItem.user_type_name = ''
              customItem.user_type_name_bn = ''
            }
            const orgItem = {}
            const orgObj = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(org => org.value === item.professional_info.org_id)
            if (typeof orgObj !== 'undefined') {
              orgItem.organization_name = orgObj.text_en
              orgItem.organization_name_bn = orgObj.text_bn
            } else {
              orgItem.organization_name = ''
              orgItem.organization_name_bn = ''
            }
            const jobTypeItem = {}
            const jobTypeObj = this.$store.state.TrainingElearning.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(item.professional_info.job_type_id))
            if (typeof jobTypeObj !== 'undefined') {
              jobTypeItem.job_name = jobTypeObj.text_en
              jobTypeItem.job_name_bn = jobTypeObj.text_bn
            } else {
              jobTypeItem.job_name = ''
              jobTypeItem.job_name_bn = ''
            }
            const gradeItem = {}
            const gradeObj = this.$store.state.commonObj.gradeList.find(grade => grade.value === item.professional_info.grade_id)
            if (typeof gradeObj !== 'undefined') {
              gradeItem.grade_name = gradeObj.text
              gradeItem.grade_name_bn = gradeObj.text_bn
            } else {
              gradeItem.grade_name = ''
              gradeItem.grade_name_bn = ''
            }
            const areaItem = {}
            const areaObj = this.$store.state.commonObj.areaTypeList.find(area => area.value === item.address.pre_area_type_id)
            if (typeof areaObj !== 'undefined') {
              areaItem.area_name = areaObj.text_en
              areaItem.area_name_bn = areaObj.text_bn
            } else {
              areaItem.area_name = ''
              areaItem.area_name_bn = ''
            }
            const perareaItem = {}
            const perareaObj = this.$store.state.commonObj.areaTypeList.find(area => area.value === item.address.per_area_type_id)
            if (typeof perareaObj !== 'undefined') {
              perareaItem.per_area_name = perareaObj.text_en
              perareaItem.per_area_name_bn = perareaObj.text_bn
            } else {
              perareaItem.per_area_name = ''
              perareaItem.per_area_name_bn = ''
            }
            const divisionItem = {}
            const divisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === item.address.pre_division_id)
            if (typeof divisionObj !== 'undefined') {
              divisionItem.division_name = divisionObj.text_en
              divisionItem.division_name_bn = divisionObj.text_bn
            } else {
              divisionItem.division_name = ''
              divisionItem.division_name_bn = ''
            }
            const perdivisionItem = {}
            const perdivisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === item.address.per_division_id)
            if (typeof perdivisionObj !== 'undefined') {
              perdivisionItem.per_division_name = perdivisionObj.text_en
              perdivisionItem.per_division_name_bn = perdivisionObj.text_bn
            } else {
              perdivisionItem.per_division_name = ''
              perdivisionItem.per_division_name_bn = ''
            }
            const districtItem = {}
            const districtObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === item.address.pre_district_id)
            if (typeof divisionObj !== 'undefined') {
              districtItem.district_name = districtObj.text_en
              districtItem.district_name_bn = districtObj.text_bn
            } else {
              districtItem.district_name = ''
              districtItem.district_name_bn = ''
            }
            const perdistrictItem = {}
            const perdistrictObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === item.address.per_district_id)
            if (typeof perdivisionObj !== 'undefined') {
              perdistrictItem.per_district_name = perdistrictObj.text_en
              perdistrictItem.per_district_name_bn = perdistrictObj.text_bn
            } else {
              perdistrictItem.per_district_name = ''
              perdistrictItem.per_district_name_bn = ''
            }
            const upazillaItem = {}
            const upazillaObj = this.$store.state.CommonService.commonObj.upazilaList.find(upazilla => upazilla.value === item.address.pre_upazilla_id)
            if (typeof upazillaObj !== 'undefined') {
              upazillaItem.upazilla_name = upazillaObj.text_en
              upazillaItem.upazilla_name_bn = upazillaObj.text_bn
            } else {
              upazillaItem.upazilla_name = ''
              upazillaItem.upazilla_name_bn = ''
            }
            const perupazillaItem = {}
            const perupazillaObj = this.$store.state.CommonService.commonObj.upazilaList.find(perupazilla => perupazilla.value === item.address.per_upazilla_id)
            if (typeof perupazillaObj !== 'undefined') {
              perupazillaItem.per_upazilla_name = perupazillaObj.text_en
              perupazillaItem.per_upazilla_name_bn = perupazillaObj.text_bn
            } else {
              perupazillaItem.per_upazilla_name = ''
              perupazillaItem.per_upazilla_name_bn = ''
            }
            const cityCorporationItem = {}
            const cityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === item.address.pre_city_corporation_id)
            if (typeof cityCorporationObj !== 'undefined') {
              cityCorporationItem.cityCorporation_name = cityCorporationObj.text_en
              cityCorporationItem.cityCorporation_name_bn = cityCorporationObj.text_bn
            } else {
              cityCorporationItem.cityCorporation_name = ''
              cityCorporationItem.cityCorporation_name_bn = ''
            }
            const percityCorporationItem = {}
            const percityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === item.address.pre_city_corporation_id)
            if (typeof percityCorporationObj !== 'undefined') {
              percityCorporationItem.per_cityCorporation_name = percityCorporationObj.text_en
              percityCorporationItem.per_cityCorporation_name_bn = percityCorporationObj.text_bn
            } else {
              percityCorporationItem.per_cityCorporation_name = ''
              percityCorporationItem.per_cityCorporation_name_bn = ''
            }
            const unionItem = {}
            const unionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === item.address.pre_union_id)
            if (typeof unionObj !== 'undefined') {
              unionItem.union_name = unionObj.text_en
              unionItem.union_name_bn = unionObj.text_bn
            } else {
              unionItem.union_name = ''
              unionItem.union_name_bn = ''
            }
            const perunionItem = {}
            const perunionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === item.address.per_union_id)
            if (typeof perunionObj !== 'undefined') {
              perunionItem.per_union_name = perunionObj.text_en
              perunionItem.per_union_name_bn = perunionObj.text_bn
            } else {
              perunionItem.per_union_name = ''
              perunionItem.per_union_name_bn = ''
            }
            const wardItem = {}
            const wardObj = this.$store.state.CommonService.commonObj.wardList.find(ward => ward.value === item.address.pre_ward_id)
            if (typeof wardObj !== 'undefined') {
              wardItem.ward_name = wardObj.text_en
              wardItem.ward_name_bn = wardObj.text_bn
            } else {
              wardItem.ward_name = ''
              wardItem.ward_name_bn = ''
            }
            const perwardItem = {}
            const perwardObj = this.$store.state.CommonService.commonObj.wardList.find(ward => ward.value === item.address.per_ward_id)
            if (typeof perwardObj !== 'undefined') {
              perwardItem.per_ward_name = perwardObj.text_en
              perwardItem.per_ward_name_bn = perwardObj.text_bn
            } else {
              perwardItem.per_ward_name = ''
              perwardItem.per_ward_name_bn = ''
            }
            const pauroshobaItem = {}
            const pauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(per => per.value === item.address.pre_pauroshoba_id)
            if (typeof pauroshobaObj !== 'undefined') {
              pauroshobaItem.pauroshoba_name = pauroshobaObj.text_en
              pauroshobaItem.pauroshoba_name_bn = pauroshobaObj.text_bn
            } else {
              pauroshobaItem.pauroshoba_name = ''
              pauroshobaItem.pauroshoba_name_bn = ''
            }
            const perpauroshobaItem = {}
            const perpauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(per => per.value === item.address.per_pauroshoba_id)
            if (typeof perpauroshobaObj !== 'undefined') {
              perpauroshobaItem.per_pauroshoba_name = perpauroshobaObj.text_en
              perpauroshobaItem.per_pauroshoba_name_bn = perpauroshobaObj.text_bn
            } else {
              perpauroshobaItem.per_pauroshoba_name = ''
              perpauroshobaItem.per_pauroshoba_name_bn = ''
            }
            const genderItem = {}
            const genderObj = this.genderList.find(per => per.value === item.gender)
            if (typeof genderObj !== 'undefined') {
              genderItem.gender_name = genderObj.text_en
              genderItem.gender_name_bn = genderObj.text_bn
            } else {
              genderItem.gender_name = ''
              genderItem.gender_name_bn = ''
            }
            const maritalItem = {}
            const maritalObj = this.maritalList.find(per => per.value === item.marital_status)
            if (typeof maritalObj !== 'undefined') {
              maritalItem.marital_name = maritalObj.text_en
              maritalItem.marital_name_bn = maritalObj.text_bn
            } else {
              maritalItem.marital_name = ''
              maritalItem.marital_name_bn = ''
            }
            const bloodItem = {}
            const bloodObj = this.bloodGroupList.find(blood => blood.value === item.blood_group)
            if (typeof bloodObj !== 'undefined') {
              bloodItem.blood_name = bloodObj.text
              bloodItem.blood_name_bn = bloodObj.text_bn
            } else {
              bloodItem.blood_name = ''
              bloodItem.blood_name_bn = ''
            }
            const religionItem = {}
            const religionObj = this.religionList.find(religion => religion.value === item.religion)
            if (typeof religionObj !== 'undefined') {
              religionItem.religion_name = religionObj.text_en
              religionItem.religion_name_bn = religionObj.text_bn
            } else {
              religionItem.religion_name = ''
              religionItem.religion_name_bn = ''
            }

            return Object.assign({}, item, { serial: index }, customItem, orgItem, gradeItem, areaItem, perareaItem, divisionItem, perdivisionItem, districtItem, perdistrictItem, upazillaItem, perupazillaItem, cityCorporationItem, percityCorporationItem, unionItem, perunionItem, wardItem, perwardItem, pauroshobaItem, perpauroshobaItem, genderItem, maritalItem, bloodItem, religionItem, jobTypeItem)
          })
          return listData
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        },
        pdfExport () {
          const reportTitle = this.$i18n.locale === 'en' ? 'Registration' : 'নিবন্ধন'
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
        }
    }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
