<template>
  <body-card>
    <template>
      <b-overlay :show="loading">
          <b-row>
              <b-col>
                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="profile.professional_info.org_type_id === 1 ? orgMoc(profile.professional_info.org_id) : 10 ">
                      <div class="titleDiv">
                      </div>
                  </list-report-head>
              </b-col>
          </b-row>
        <b-row>
            <b-col md="3" class="text-center align-top iq-border-r-5 pt-3">
                <img class="img-thumbnail w-50" style="height: 130px;" :src="baseUrl + 'download-attachment?file=' + profile.image" alt="Profile Image">
            </b-col>
            <b-col md="9">
                <table class="table table-sm table-borderless">
                    <tr>
                        <th width="15%">{{$t('globalTrans.name')}}</th>
                        <td width="35%">: {{ ($i18n.locale=='bn') ? profile.name_bn : profile.name }}</td>
                        <th width="15%">{{$t('globalTrans.email')}}</th>
                        <td width="35%">: {{ ($i18n.locale=='bn') ? profile.email : profile.email }}</td>
                    </tr>
                    <tr>
                        <th>{{$t('globalTrans.mobile')}}</th>
                        <td>: {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping: false }) }}</td>
                        <th>{{ $t('globalTrans.nid') }}</th>
                        <td>: {{ $n(profile.nid, { useGrouping: false }) }}</td>
                    </tr>
                    <tr>
                        <th>{{$t('globalTrans.gender')}}</th>
                        <td>: {{ profile.gender ? getGenderName(profile.gender) : '' }}</td>
                        <th>{{$t('globalTrans.dob')}}</th>
                        <td>: {{ profile.date_of_birth | dateFormat }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('externalTraining.mat_status') }}</th>
                        <td>: {{ ($i18n.locale=='bn') ? profile.marital_name_bn : profile.marital_name }}</td>
                        <th>{{ $t('externalTraining.blood_group') }}</th>
                        <td>: {{ ($i18n.locale=='bn') ? profile.blood_name_bn : profile.blood_name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('externalTraining.religion') }}</th>
                        <td>: {{ ($i18n.locale=='bn') ? profile.religion_name_bn : profile.religion_name }}</td>
                        <th>{{ $t('elearning_iabm.emergency_contact') }}</th>
                        <td>: {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.emergency_contact, { useGrouping: false }) }}</td>
                    </tr>
                </table>
                <b-row>
                    <b-col lg="12" sm="12">
                        <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                          {{ $t('externalTraining.professional_info') + ' :' }}
                        </p>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <table class="table table-sm table-borderless">
                          <tr>
                              <th width="15%">{{ $t('elearning_config.organization_type') }}</th>
                              <td width="35%">: {{ ($i18n.locale=='bn') ? profile.org_type_name_bn : profile.org_type_name }}</td>
                              <th width="15%">{{ $t('globalTrans.organization') }}</th>
                              <td width="35%">:
                                <span v-if="profile.professional_info.org_type_id === 3">{{ ($i18n.locale=='bn') ? profile.professional_info.org_name_bn : profile.professional_info.org_name_en }}</span>
                                <span v-else>{{ ($i18n.locale=='bn') ? profile.organization_name_bn : profile.organization_name }}</span>
                              </td>
                          </tr>
                          <tr>
                              <th>{{ $t('externalTraining.designation') }}</th>
                              <td>: {{ ($i18n.locale=='bn') ? profile.professional_info.designation_bn : profile.professional_info.designation_en }}</td>
                              <th>{{ $t('elearning_iabm.job_type') }}</th>
                              <td>: {{ ($i18n.locale=='bn') ? profile.job_name_bn : profile.job_name }}</td>
                          </tr>
                          <tr>
                              <th>{{ $t('elearning_iabm.batch') }}</th>
                              <td>: {{ profile.professional_info.batch }}</td>
                              <th>{{ $t('externalTraining.service_id') }}</th>
                              <td>: {{ $n(profile.professional_info.service_id, { useGrouping: false }) }}</td>
                          </tr>
                          <tr>
                              <th>{{ $t('externalTraining.pay_grade') }}</th>
                              <td>: {{ ($i18n.locale=='bn') ? profile.grade_name_bn : profile.grade_name }}</td>
                              <th>{{ $t('externalTraining.designation_c') }}</th>
                              <td>: {{ profile.professional_info.controlling_authority }}</td>
                          </tr>
                          <tr>
                              <th>{{ $t('externalTraining.telephone_c') }}</th>
                              <td>: {{ profile.professional_info.telephone }}</td>
                              <th>{{ $t('externalTraining.email_c') }}</th>
                              <td>: {{ profile.professional_info.email }}</td>
                          </tr>
                          <tr>
                              <th>{{ $t('externalTraining.office_mail') }}</th>
                              <td>: {{ profile.professional_info.office_mail_address }}</td>
                              <th>{{ $t('externalTraining.email_c') }}</th>
                              <td>: {{ profile.professional_info.email }}</td>
                          </tr>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                        <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                         {{ $t('externalTraining.academic_info') + ' :' }}
                        </p>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <table class="table table-sm table-borderless">
                          <tr>
                              <th width="15%">{{ $t('externalTraining.highest_degree') }}</th>
                              <td width="35%">: {{ profile.name_of_highest_degree }}</td>
                              <th width="15%">{{ $t('externalTraining.board_university') }}</th>
                              <td width="35%">:
                                {{ profile.board }}
                              </td>
                          </tr>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                        <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                            {{ $t('globalTrans.present_address') + ' :' }}
                        </p>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <table class="table table-sm table-borderless">
                          <tr>
                              <th width="15%">{{ $t('globalTrans.area_type') }}</th>
                              <td width="35%">: {{ ($i18n.locale=='bn') ? profile.area_name_bn : profile.area_name }}</td>
                              <th width="15%">{{ $t('globalTrans.division') }}</th>
                              <td width="35%">: {{ ($i18n.locale=='bn') ? profile.division_name_bn : profile.division_name }}</td>
                          </tr>
                          <tr>
                              <th>{{ $t('globalTrans.district') }}</th>
                              <td>: {{ ($i18n.locale=='bn') ? profile.district_name_bn : profile.district_name }}</td>
                              <th v-if="item.address.pre_area_type_id == 1">{{$t('globalTrans.city_corporation')}}</th>
                              <td v-if="item.address.pre_area_type_id == 1">: {{ ($i18n.locale=='bn') ? profile.cityCorporation_name_bn : profile.cityCorporation_name }}</td>
                          </tr>
                          <tr>
                              <th v-if="item.address.pre_area_type_id == 2">{{$t('globalTrans.pouroshova')}}</th>
                              <td v-if="item.address.pre_area_type_id == 2">: {{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</td>
                              <th v-if="item.address.pre_area_type_id == 3">{{$t('globalTrans.union')}}</th>
                              <td v-if="item.address.pre_area_type_id == 3">: {{ ($i18n.locale=='bn') ? profile.per_union_name_bn : profile.per_union_name }}</td>
                          </tr>
                          <tr>
                              <th v-if="item.address.pre_area_type_id == 3||2">{{$t('globalTrans.ward')}}</th>
                              <td v-if="item.address.pre_area_type_id == 3||2">: {{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name }}</td>
                              <th v-if="item.address.pre_area_type_id == 3||2">{{$t('globalTrans.upazila')}}</th>
                              <td v-if="item.address.pre_area_type_id == 3||2">: {{ ($i18n.locale=='bn') ? profile.upazilla_name_bn : profile.upazilla_name }}</td>
                          </tr>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                        <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                            {{ $t('globalTrans.permanent_address') + ' :' }}
                        </p>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <table class="table table-sm table-borderless">
                          <tr>
                              <th width="15%">{{$t('globalTrans.area_type')}}</th>
                              <td width="35%">: {{ ($i18n.locale=='bn') ? profile.per_area_name_bn : profile.per_area_name }}</td>
                              <th width="15%">{{$t('globalTrans.division')}}</th>
                              <td width="35%">: {{ ($i18n.locale=='bn') ? profile.per_division_name_bn : profile.per_division_name }}</td>
                          </tr>
                          <tr>
                              <th>{{ $t('globalTrans.district') }}</th>
                              <td>: {{ ($i18n.locale=='bn') ? profile.per_district_name_bn : profile.per_district_name }}</td>
                              <th v-if="item.address.per_area_type_id == 3||2">{{ $t('globalTrans.upazila') }}</th>
                              <td v-if="item.address.per_area_type_id == 3||2">: {{ ($i18n.locale=='bn') ? profile.per_upazilla_name_bn : profile.per_upazilla_name }}</td>
                          </tr>
                          <tr>
                              <th v-if="item.address.pre_area_type_id == 3">{{ $t('globalTrans.union') }}</th>
                              <td v-if="item.address.pre_area_type_id == 3">: {{ ($i18n.locale=='bn') ? profile.per_union_name_bn : profile.per_union_name }}</td>
                              <th v-if="item.address.per_area_type_id == 3||2">{{ $t('globalTrans.ward') }}</th>
                              <td v-if="item.address.per_area_type_id == 3||2">: {{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name }}</td>
                          </tr>
                          <tr>
                              <th v-if="item.address.per_area_type_id == 2">{{ $t('globalTrans.pouroshova') }}</th>
                              <td v-if="item.address.per_area_type_id == 2">: {{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</td>
                              <th v-if="item.address.pre_area_type_id == 1">{{$t('globalTrans.city_corporation')}}</th>
                              <td v-if="item.address.pre_area_type_id == 1">: {{ ($i18n.locale=='bn') ? profile.per_cityCorporation_name_bn : profile.per_cityCorporation_name }}</td>
                          </tr>
                        </table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
      </b-overlay>
    </template>
  </body-card>
</template>
<script>
// import ExportPdf from './export-pdf_details_registration'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { personalInfoShow } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    components: {
      ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
        this.profile = this.item
    },
    data () {
        return {
            baseUrl: trainingElearningServiceBaseUrl,
            profile: [],
            slOffset: 1,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    methods: {
      orgMoc (orgId) {
        const orgData = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(item => item.value === orgId)
        return orgData.org_id
      },
      getGenderName (val) {
        const Obj = this.$store.state.commonObj.genderList.find(el => el.value === val)
        return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
      },
      getOrganization (id) {
        if (this.profile.professional_info.org_type_id === 3) {
          return this.$i18n.locale === 'bn' ? this.profile.professional_info.org_name_bn : this.profile.professional_info.org_name_en
        } else {
          const organization = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(item => item.value === parseInt(id))
          if (typeof organization !== 'undefined') {
            return this.$i18n.locale === 'en' ? organization.text_en : organization.text_bn
          } else {
            return ''
          }
        }
      },
      async pdfExport () {
        this.customloading = true
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, id: this.item.id })
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, personalInfoShow, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.customloading = false
      }
      // pdfExport () {
      //   const reportTitle = this.$i18n.locale === 'en' ? 'Registration Details' : 'নিবন্ধনের বিবরণ'
      //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this)
      // }
    }
}
</script>

<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
